$font-family: 'NotoSans-Regular';
$font-size-default: 1.2em;
$font-size-small: 0.5em;
$font-size-big: 1.5em;
$font-size-huge: 2em;

@font-face {
	font-family: 'NotoSans-Regular';
	src: url('/fonts/NotoSans-Regular.ttf') format('truetype');
	font-display: swap;
}
