.footer {
	border-top: white;
	border-top-style: double;
	padding-left: 0.25rem;
	padding-right: 0.25rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 0;

	a {
		color: $font-color;
	}

	.right {
		text-align: right;
	}
} 
