.post {
	margin: 5px;
	padding: 5px;
	background-color: $background-color-secondary;
	border: 1px solid black;

	&:hover
	{
		background-color: $accent-color-primary;
	}

	a {
		width: 100%;
		display: flex;
		justify-content: space-around;
	}

	.title {
		width: 100%;
		padding: 5px 0px 3px;
	}

	.date {
		color: $font-color;
		padding: 5px 15px 3px;
	}
}

.content {
	width: 800px;
}
