.header {
	padding-left: 0.25rem;
	border-bottom: $font-color;
	border-bottom-style: double;
	display: flex;
	justify-content: space-between;

	.left {
		font-size: $font-size-huge;
	}
} 
