.item {
	display: block;
	width: 300px;
	margin: 4px;
	padding: 5px;
	background-color: $background-color-secondary;
	border: 1px solid black;

	&:hover
	{
		background-color: $accent-color-primary;
	}

	.thumb {
		display: block;
		height: 300px;
		width: 300px;
	}

	.title {
		width: 300px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 5px 0px 3px;
		text-overflow: ellipsis;
		border-bottom-style: solid;
		border-bottom-color: $font-color;
		border-bottom-width: 2px;
	}

	.links {
		padding-left: 0.25rem;
		margin-top: 0.25em;
	}
}

.item-list {
	width: 100%;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	row-gap: 1em;
}

.item-detail {
	justify-content: space-around;
	align-content: flex-start;
	padding: 25px 0px;
	display: flex;
	column-gap: 2em;
	flex-wrap: wrap;
	flex: 1;
}

.item-description {
	flex: 1;
	min-width: 400px;
	overflow: hidden;
	text-align: start;
}
