@import 'colors';
@import 'fonts';
@import 'footer';
@import 'header';
@import 'item';
@import 'post';

* {
	margin: 0;
	text-decoration: none;
}

body,
html {
	background-color: $background-color-primary;
	color: $font-color;
	font-family: $font-family;
	font-size: 1.2em;
	word-wrap: break-word;
	text-overflow: clip;
}

a {
	color: $font-color;
}

p {
	padding-bottom: 0.5em;
}

#main {
	max-width: 960px;
	padding: 0px 20px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}

.sidebar {
    position: absolute;
	text-align: right;
    top: 50px;
    right: 100%;
	list-style-type: none;
	width: 100%;

	li {
		border-style: solid;
		border-width: 1px;
	}
}

#content {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.section {
	margin-bottom: 1em;
} 

.section-header {
	padding-left: 0.25rem;
	border-bottom: $font-color;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	margin-bottom: 0.3em;
	font-size: $font-size-big;
}

.icon {
	height: 24px;
	width: 24px;
	filter: invert(50%);

	&:hover
	{
		filter: invert(75%);
	}
}
